<template>
  <v-dialog v-model="dialogEditShow" max-width="500px">
    <v-card class="pa-4 pa-sm-8">
      <v-btn class="button-close ma-4" icon @click="dialogEditShow=false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <h2 v-if="title" class="text-center">{{title}}</h2>
      <div :class="{'text-center': centerText}" v-html="text"></div>
      <div class="row mt-4 justify-end">
          <v-btn class="mr-3 _w-100" _color="primary" @click="dialogEditShow=false"> Закрыть </v-btn>
          <v-btn class="_w-100" color="primary" @click="okClicked"> {{ textOk }} </v-btn>
       </div>
    </v-card>
  </v-dialog>
</template>
<script>
/*eslint-disable*/
  export default {
  model: {
     prop: 'value',
     event: 'input'
  },
  props: {
    value: Boolean,
    text: {
      type: String,
      default: "Вы уверены, что хотите удалить?<br>Действие не может быть отменено."
    },
    textOk: {
      type: String,
      default: "Удалить"
    },
    title: String,
    centerText: Boolean,
  },
  data: () => ({
  }),
  watch: {
    async dialogEditShow(newVal) {
      if (newVal) {
      }
    }
  },
  computed: {
    dialogEditShow: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', false);
      }
    },
  },
  methods: {
    okClicked() {
      this.$emit('ok');
      this.dialogEditShow = false
    }
  }
};
</script>


